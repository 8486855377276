

body {
    background-color:  #00838f;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    font-weight: bolder;
  color: #263238  ;

  }

  main {
    flex: 1 0 auto;
  }

  /* .card {
    /* margin: 0 1rem 1rem 1rem; */
  /* }  */

  .btn a {
    color: white;
    font-family: 'Comfortaa', cursive;
   }

   .profile-pic {
     margin-top: 2rem;
   }

   
   .nav-name {
     padding-left: 10px;
   }