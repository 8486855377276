.App {
  text-align: center;
}
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  font-family: 'Comfortaa', cursive;
}


main {
  flex: 1 0 auto;
}
